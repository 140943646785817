import "@rainbow-me/rainbowkit/styles.css";
import "./polyfills";
import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import {
  getDefaultConfig,
  midnightTheme,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import { base, baseSepolia } from "wagmi/chains";
import App from "./App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { EcardSocketProvider } from "./providers/EcardProvider";
import { BrowserRouter } from "react-router-dom";
import AccountContextProvider from "./providers/AccountProvider";
import { ContractDataProvider } from "./providers/ContractDataProvider";
import { config } from "./config";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const wagmiConfig = getDefaultConfig({
  appName: "GambleHaus",
  projectId: "",
  chains: [
    config.enviroment === "mainnet" ? base : baseSepolia,
    // ...(process.env.REACT_APP_ENABLE_TESTNETS === 'true' ? [sepolia] : []),
  ],
  wallets: [],
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();
//remove <React.StrictMode> with <></>
root.render(
  <>
    <BrowserRouter>
      <WagmiProvider config={wagmiConfig}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider theme={midnightTheme()}>
            <ContractDataProvider>
              <EcardSocketProvider>
                <AccountContextProvider>
                  <App />
                </AccountContextProvider>
              </EcardSocketProvider>
            </ContractDataProvider>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </BrowserRouter>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
    {/* Same as */}
    <ToastContainer />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
